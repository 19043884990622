import React from 'react'
import styled from 'styled-components'

import EventResultsList from '../../modules/eventResults'
import WidthContainer from '../../elements/widthContainer'

const Wrapper = styled.div``

const UpcomingEventsSmall = ({ events, title }) => {
  return (
    <Wrapper>
      <WidthContainer variant="full">
        <EventResultsList events={events} isShort={true} shortTitle={title} />
      </WidthContainer>
    </Wrapper>
  )
}

export default UpcomingEventsSmall
