const fieldDict = {
  name: 'Naam',
  description: 'Beschrijving',
  teamname: 'Teamnaam',
  type: 'Type',
  sports: 'Sporten',
  logo: 'Logo',
  header_image: 'Header afbeelding',
  header_files: 'Header afbeelding',
  startdate: 'Start datum',
  enddate: 'Eind datum',
  contact_name: 'Naam (contact)',
  contact_email: 'E-mailadres (contact)',
  contact_telephone: 'Telefoonnummer (contact)',
  email: 'E-mailadres (evenement)',
  telephone: 'Telefoonnummer (contact',
}

export const displayErrors = (errors) => {
  if (!errors || errors == {}) {
    return ''
  }
  if (typeof errors == 'object') {
    let keys = Object.keys(errors)
    if (!keys || keys?.length == 0) {
      return ''
    }
    return keys
      .map((item) => {
        if (item.includes("freetext_for-")) {
          // Is used when "anders" is selected
          // In shape of freetext_for-${i}
          return "Prijs groep"
        }
        if (item in fieldDict) {
          return fieldDict[item]
        }
        return item
      })
      .join(', ')
  } else if (Array.isArray(errors)) {
    if (!errors || errors.length == 0) {
      return ''
    }
    return errors
      .map((item) => {
        if (item in fieldDict) {
          return fieldDict[item]
        }
        return item
      })
      .join(', ')
  }
  return errors
}
