import React from 'react'
import styled from 'styled-components'
import { nl } from 'date-fns/locale'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import TypographyElement from '../../elements/typography'
import Field, { FieldContainer, FormRow } from '../../elements/fields'
import { Form } from '../../elements/form'
import ButtonElement from '../../elements/buttons'
import { red } from '../../colors'
import {
  dateValidation,
  eventTitleValidation,
  urlApiValidation,
  filesizesValidation,
  validation,
} from '../../utils/formValidation'
import { ImageBuilderFrontend } from '../../elements/imageBuilder'
import { getMomentTz } from '../../utils/getMomentTz'
import CSRFToken from '../../elements/csrf'
import { postEventApi } from '../../utils/api/fetchApi'
import { FormHelperText, InputLabel } from '@mui/material'
import { displayErrors } from '../../utils/displayErrors'

const FormWrapper = styled.div`
  max-width: 761px;
  .subform {
    display: block;
    margin-top: 40px;
  }
  .buttons {
    > * {
      display: inline-flex;
      vertical-align: middle;
      width: 50%;
      margin-top: 16px;
      :first-child {
        text-align: left;
      }
      :last-child {
        text-align: right;
      }
    }
    b {
      display: inline;
      margin-right: 1rem;
    }
  }
  .image-preview {
    display: inline-block;
    height: 100px;
    width: 150px;
    margin-right: 20px;
    margin-top: 20px;
    position: relative;
    span {
      height: 100px;
      width: 150px;
      img {
        object-position: center;
        object-fit: contain;
      }
    }
    .remove {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 20px;
      font-weight: 700;
      padding: 0 10px;
      color: ${red};
      cursor: pointer;
      outline-color: red !important;
    }
  }
`

const forOptions = [
  {
    name: 'Kinderen',
    value: 'kinderen',
  },
  {
    name: 'Volwassenen',
    value: 'volwassenen',
  },
  {
    name: 'Ouderen',
    value: 'ouderen',
  },
  {
    name: 'Studenten',
    value: 'studenten',
  },
  {
    name: 'Iedereen',
    value: 'iedereen',
  },
  {
    name: 'Anders, namelijk',
    value: 'anders',
  },
]

const countryOptions = [
  {
    name: 'Nederland',
    value: 'Nederland',
  },
  {
    name: 'België',
    value: 'België',
  },
  {
    name: 'Duitsland',
    value: 'Duitsland',
  },
]

const AddEventForm = ({
  handleFormSubmit,
  eventOptions,
  sportOptions,
  clubOptions,
  attributeOptions,
  locationOptions,
  host,
}) => {
  const [titleError, setTitleError] = React.useState('')
  const [processing, setProcessing] = React.useState(false)
  const [agreed, setAgreed] = React.useState(false)
  const [values, setValues] = React.useState({
    country: countryOptions[0].value,
    type: eventOptions?.length ? eventOptions[0].value : '',
  })
  const [fileError, setFileError] = React.useState('')
  const [error, setError] = React.useState('')
  const [errors, setErrors] = React.useState({})
  const [prices, setPrices] = React.useState([
    {
      price: '',
      for: '',
      freetext_for: '',
    },
  ])
  const [starttime, setStarttime] = React.useState('')
  const [endtime, setEndtime] = React.useState('')

  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleChange = (e) => {
    const newState = { ...values }
    const { name, value } = e.target
    newState[name] = value
    if (errors && Object.keys(errors).length > 0) {
      const errorState = handleValidationErrors(value, name)
      setErrors(errorState)
    }
    setValues(newState)
  }

  const handleMultiChange = (e) => {
    const newState = { ...values }
    const { name, value } = e.target

    newState[name] = typeof value === 'string' ? value.split(',') : value
    if (errors && Object.keys(errors).length > 0) {
      const errorState = handleValidationErrors(value, name)
      setErrors(errorState)
    }
    setValues(newState)
  }

  const handleAutocompleteChange = (e, value, name) => {
    const newState = { ...values }
    newState[name] = value
    setValues(newState)
  }

  const handlePriceChange = (value, name, i) => {
    const newPrices = [...prices]
    newPrices[i][name] = value
    setPrices(newPrices)
    const priceState = {}
    newPrices.forEach((item) => {
      const for_name = item['freetext_for'] || item['for']
      priceState[for_name] = item['price']
    })
    const newState = { ...values, prices: newPrices }
    setValues(newState)
  }

  const handleExtraPrice = () => {
    const newPrices = [...prices]
    newPrices.push({
      price: '',
      for: '',
      freetext_for: '',
    })
    setPrices(newPrices)
  }

  // function used to create a smaller version of the thumbnail
  const resizeBase64Img = async (base64) => {
    return new Promise((resolve, reject) => {
      var canvas = document.createElement('canvas')
      let context = canvas.getContext('2d')
      let img = document.createElement('img')
      img.src = base64
      img.onload = function () {
        let originalHeight = img.naturalHeight
        let originalWidth = img.naturalWidth
        let scale = 1
        canvas.height = originalHeight
        canvas.width = originalWidth
        context.scale(scale, scale)
        context.drawImage(img, 0, 0)
        resolve({
          data: canvas.toDataURL(),
          width: originalWidth,
          height: originalHeight,
        })
      }
    })
  }

  const handleFileUpload = async (e) => {
    if (!e.target.files) {
      return
    }
    const filedata = e.target.files[0]
    const url = URL.createObjectURL(filedata)
    const data = await resizeBase64Img(url)

    const width = data?.width
    const height = data?.height
    const filesize = filedata?.size

    // Validate file
    if (width < 1600 || height < 600 || filesize > 3000000) {
      setFileError(
        'Het formaat voldoet niet aan de eisen (1600x1200 en maximaal 3MB)'
      )
      return
    }
    setFileError('')

    const file = {
      name: filedata.name,
      file: data?.data,
      width: width,
      height: height,
      preview: url,
      filesize: filesize,
    }
    const newFiles = 'files' in values ? [...values['files'], file] : [file]

    setValues({ ...values, files: newFiles })

    if (values['files']) {
      const fileError = filesizesValidation(newFiles)
      if (fileError) {
        const newErrors = { ...errors }
        newErrors['files'] = fileError
        setErrors(newErrors)
      }
    }
  }

  const handleRemoveFile = (file) => {
    if (!file?.file) {
      return
    }
    const newFiles = [...values['files']].filter((item) => {
      return item.file != file.file
    })

    setValues({ ...values, files: newFiles })
    setFileError('')

    if (errors['files']) {
      const fileError = filesizesValidation(newFiles)
      if (!fileError) {
        const newErrors = { ...errors }
        delete newErrors['files']
        setErrors(newErrors)
      }
    }
  }

  const handleHeaderValidation = (newFiles) => {
    // Function to handle the validation of the header_files input (Header afbeelding)
    if (newFiles?.length === 0) {
      // use newFiles null for validation
      newFiles = null
    }
    let errorState = handleValidationErrors(newFiles, 'header_files')
    if (!errorState) {
      const fileError = filesizesValidation(newFiles)
      if (fileError) {
        errorState = {
          ...errors,
          header_files: fileError
        }
      }
    }
    setErrors(errorState)
  }

  const handleHeaderFileUpload = async (e) => {
    if (!e.target.files) {
      return
    }
    const filedata = e.target.files[0]
    const url = URL.createObjectURL(filedata)
    const data = await resizeBase64Img(url)

    const width = data?.width
    const height = data?.height
    const filesize = filedata?.size

    // Validate file
    if (width < 1600 || height < 600 || filesize > 3000000) {
      setFileError(
        'Het formaat voldoet niet aan de eisen (1600x1200 en maximaal 3MB)'
      )
      return
    }
    setFileError('')

    const file = {
      name: filedata.name,
      file: data?.data,
      width: width,
      height: height,
      preview: url,
      filesize: filesize,
    }
    const newFiles = [file]

    setValues({ ...values, header_files: newFiles })
    handleHeaderValidation(newFiles)
  }

  const handleRemoveHeaderFile = (file) => {
    if (!file?.file) {
      return
    }
    const newFiles = [...values['header_files']].filter((item) => {
      return item.file != file.file
    })

    setValues({ ...values, header_files: newFiles })
    setFileError('')
    handleHeaderValidation(newFiles)
  }

  const handleTimeChange = (value, name) => {
    const newState = { ...values }
    newState[name] = value
    if (errors && Object.keys(errors).length > 0) {
      const errorState = handleValidationErrors(value, name)
      setErrors(errorState)
    }
    setValues(newState)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const validTitle = await eventTitleValidation(
      values['name'],
      values['startdate']
    )
    if (!validTitle?.valid) {
      setTitleError(validTitle?.message)
    }
    if (processing || !validTitle?.valid) {
      // Either processing or no valid title
      // Do nothing
      return
    }
    setProcessing(true)
    const csrftoken = e.target.csrfmiddlewaretoken.value
    const data = new FormData(e.target)
    const entries = [...data.entries()]

    const errorState = handleAllValidation(entries)
    setErrors(errorState)

    const token = await executeRecaptcha('AddEventFormSubmit')

    if (token && Object.keys(errorState).length == 0) {
      // handle api post
      const uploadFiles = []
      if ('files' in values) {
        values['files'].forEach((file) => {
          uploadFiles.push({
            file: file.file,
            height: file.height,
            width: file.width,
            name: file.name,
          })
        })
      }
      const uploadHeaderFiles = []
      if ('header_files' in values) {
        values['header_files'].forEach((file) => {
          uploadHeaderFiles.push({
            file: file.file,
            height: file.height,
            width: file.width,
            name: file.name,
          })
        })
      }
      postEventApi(
        host,
        'api/addevent/',
        entries,
        uploadFiles,
        uploadHeaderFiles,
        csrftoken,
        token
      )
        .then((res) => {
          if (res.success) {
            handleFormSubmit()
            setProcessing(false)
          } else {
            console.error(res)
            setError(res?.message || res?.error || 'Er is iets misgegaan!')
            setProcessing(false)
          }
        })
        .catch((e) => {
          console.error(e)
          setError(e?.message || 'Er is iets misgegaan!')
          setProcessing(false)
        })
    } else {
      setProcessing(false)
    }
  }

  const handleAllValidation = (entries) => {
    const newErrors = {}
    entries.forEach((entry) => {
      const name = entry[0]
      const value = entry[1] == 'placeholder' ? '' : entry[1]
      const error = (name.includes("freetext_for") && !value) ?
        "Verplicht" : validation(value, name, 'addevent')
      if (error) {
        newErrors[name] = error
      }
    })

    // Add validation step for header files. These are not included in entries
    // but are required.
    const headerName = 'header_files'
    const headerError = validation(
      values[headerName],
      headerName,
      'addevent'
    )
    if (headerError) {
      newErrors[headerName] = headerError
    }

    if (values['files']) {
      const fileError = filesizesValidation(values['files'])
      if (fileError) {
        newErrors['files'] = fileError
      }
    }
    if (values['header_files'] && !headerError) {
      const fileError = filesizesValidation(values['header_files'])
      if (fileError) {
        newErrors['header_files'] = fileError
      }
    }

    return newErrors
  }

  const handleValidationErrors = (value, name) => {
    const error = validation(value, name, 'addevent')
    let errorState = { ...errors }
    if (!error && name in errors) {
      delete errorState[name]
    } else if (error) {
      errorState[name] = error
    }
    return errorState
  }

  const handleDateValidationErrors = (value, name) => {
    // dateValidation(values['startdate'], values['enddate'])
    let error = validation(value, name, 'addevent')
    if (!error) {
      error = dateValidation(values['startdate'], values['enddate'])
    }
    let errorState = { ...errors }
    if (!error && name in errors) {
      delete errorState[name]
    } else if (error) {
      errorState[name] = error
    }
    return errorState
  }

  const handleBlur = (e) => {
    const { value, name } = e.target
    const errorState = handleValidationErrors(value, name)
    setErrors(errorState)
  }

  const handleBlurFreetextPrice = (e) => {
    const { value, name } = e.target
    setErrors((prevErrors) => {
      const newErrors = {...prevErrors}
      if (value) {
        delete newErrors[name]
      }
      return newErrors
    })
  }

  const handleDateBlur = (name) => {
    if (!(name in values)) {
      return
    }
    const value = values[name]
    const errorState = handleDateValidationErrors(value, name)
    setErrors(errorState)
  }

  const aangepasteAttr = attributeOptions.filter((item) =>
    item.name.includes('Aangepaste sporten - ')
  )
  const levensfaseAttr = attributeOptions.filter((item) =>
    item.name.includes('Levensfase - ')
  )
  const specialsAttr = attributeOptions.filter((item) =>
    item.name.includes('Speciale doelgroep - ')
  )

  const disabled = !agreed || Object.keys(errors).length > 0

  const readableErrors = displayErrors(errors)

  return (
    <FormWrapper>
      <Form handleSubmit={handleSubmit}>
        <LocalizationProvider adapterLocale={nl} dateAdapter={AdapterDateFns}>
          <CSRFToken />
          <div className="subform">
            <TypographyElement variant="title">
              Informatie over het evenement
            </TypographyElement>

            <FormRow>
              <Field
                variant="text"
                label="Naam van het evenement"
                required
                value={values['name']}
                handleChange={handleChange}
                id="name"
                name="name"
                handleBlur={async (e) => {
                  const { value } = e.target || {}
                  handleBlur(e)
                  // Check if title is available
                  if (value && values['startdate']) {
                    eventTitleValidation(value, values['startdate']).then(
                      (res) => {
                        if (res.valid) {
                          setTitleError()
                        } else {
                          setTitleError(res.message)
                        }
                      }
                    )
                  }
                }}
                error={errors['name'] || titleError}
              />
            </FormRow>
            <FormRow>
              <Field
                variant="multiline"
                label="Omschrijving van het evenement"
                required
                value={values['description']}
                handleChange={handleChange}
                id="description"
                name="description"
                handleBlur={handleBlur}
                error={errors['description']}
              />
            </FormRow>
            <FormRow>
              <Field
                variant="select"
                width="mid"
                label="Soort evenement"
                required
                value={values['type']}
                handleChange={handleChange}
                id="type"
                name="type"
                options={eventOptions}
                placeholder="Kies een soort evenement"
                handleBlur={handleBlur}
                error={errors['type']}
              />
            </FormRow>
            {prices?.map((item, i) => {
              // @ToDo handleblur & errors
              return (
                <FormRow key={`form-row-ind-${i}`} alignBottom>
                  <Field
                    variant="price"
                    width="mid"
                    label={
                      i == 0 ? 'Prijs (voer 0 in voor gratis evenement)' : ''
                    }
                    required={false}
                    value={item['price']}
                    handleChange={(e) => {
                      handlePriceChange(e.target?.value, 'price', i)
                    }}
                    id={`price-${i}`}
                    name={`price-${i}`}
                  />
                  <Field
                    variant="select"
                    width="small"
                    label=""
                    required={false}
                    value={item['for']}
                    handleChange={(e) => {
                      handlePriceChange(e.target?.value, 'for', i)
                    }}
                    id={`cost_for-${i}`}
                    name={`cost_for-${i}`}
                    options={forOptions}
                  />

                  {item['for'] === "anders" && (
                    <>
                      <FieldContainer width="mid" />
                      <Field
                        variant="text"
                        width="small"
                        label="Groep"
                        required={true}
                        value={item['freetext_for']}
                        handleChange={(e) => {
                          handlePriceChange(e.target?.value, 'freetext_for', i)
                        }}
                        id={`freetext_for-${i}`}
                        name={`freetext_for-${i}`}
                        error={errors[`freetext_for-${i}`]}
                        handleBlur={handleBlurFreetextPrice}
                      />
                    </>
                  )}
                </FormRow>
              )
            })}
            {prices.length != forOptions.length && (
              <ButtonElement variant="plus" handleClick={handleExtraPrice}>
                + Extra prijs toevoegen
              </ButtonElement>
            )}
          </div>
          <div className="subform">
            <FormRow>
              <Field
                variant="select"
                width="mid"
                label="Sporten evenement. Selecteer 1 of meerdere sporten."
                required
                value={values['sports'] || []}
                handleChange={handleMultiChange}
                id="sports"
                name="sports"
                options={sportOptions}
                placeholder="Selecteer de sporten"
                handleBlur={handleBlur}
                error={errors['sports']}
                multiple={true}
                boldlable={true}
              />
            </FormRow>
            <FormRow>
              <Field
                variant="autocomplete"
                width="mid"
                label="Clubs"
                description="Je kan hier organiserende clubs selecteren"
                required={false}
                value={values['clubs'] || []}
                handleChange={(e, value) => {
                  handleAutocompleteChange(e, value, 'clubs')
                }}
                options={clubOptions}
                id="clubs"
                name="clubs"
                handleBlur={handleBlur}
                error={errors['clubs']}
                boldlable={true}
                multiple={true}
              />
            </FormRow>

            <FormRow>
              <Field
                variant="select"
                width="mid"
                label="Voor welke levensfase is dit evenement."
                required={false}
                value={values['levensfase'] || []}
                handleChange={handleMultiChange}
                id="levensfase"
                name="levensfase"
                options={levensfaseAttr}
                placeholder="Selecteer de levensfase"
                handleBlur={handleBlur}
                error={errors['levensfase']}
                multiple={true}
                boldlable={true}
              />
            </FormRow>
            <FormRow>
              <Field
                variant="select"
                width="mid"
                label="Is dit evenement voor aangepast sporten?"
                required={false}
                value={values['aangepaste_sporten'] || []}
                handleChange={handleMultiChange}
                id="aangepaste_sporten"
                name="aangepaste_sporten"
                options={aangepasteAttr}
                placeholder="Selecteer de aangepaste sporten"
                handleBlur={handleBlur}
                error={errors['aangepaste_sporten']}
                multiple={true}
                boldlable={true}
              />
            </FormRow>
            <FormRow>
              <Field
                variant="select"
                width="mid"
                label="Is dit evenement voor een speciale doelgroep?"
                required={false}
                description="We gaan er vanuit dat iedereen welkom is. Selecteer als een speciaal aanbod hebt voor een bepaalde doelgroep."
                value={values['speciale_doelgroep'] || []}
                handleChange={handleMultiChange}
                id="speciale_doelgroep"
                name="speciale_doelgroep"
                options={specialsAttr}
                placeholder="Selecteer de speciale doelgroep"
                handleBlur={handleBlur}
                error={errors['speciale_doelgroep']}
                multiple={true}
                boldlable={true}
              />
            </FormRow>
          </div>
          <div className="subform">
            <TypographyElement variant="title">Datum en tijd</TypographyElement>
            <FormRow>
              <Field
                variant="date"
                width="mid"
                label="Startdatum"
                required
                value={values['startdate']}
                handleChange={(e, kbinput) => {
                  handleTimeChange(e, 'startdate')
                  if (e && values['name']) {
                    // Only validate title if there is a startdate and name
                    eventTitleValidation(values['name'], e).then((res) => {
                      if (res.valid) {
                        setTitleError()
                      } else {
                        setTitleError(res.message)
                      }
                    })
                  }
                }}
                minDate={new Date()}
                id="startdate"
                name="startdate"
                handleBlur={() => handleDateBlur('startdate')}
                error={errors['startdate']}
              />
              <Field
                variant="time"
                width="small"
                label="Starttijd"
                required={false}
                value={starttime}
                handleChange={(e, kbinput) => {
                  // @ToDo validation?
                  if (kbinput) {
                    const time =
                      kbinput.length == 1 || kbinput == 'Invalid date'
                        ? ''
                        : kbinput
                    setStarttime(time)
                    handleTimeChange(time, 'starttime')
                  } else {
                    setStarttime(e)
                    const time = getMomentTz(e).format('HH:mm')
                    handleTimeChange(time, 'starttime')
                  }
                }}
                id="starttime"
                name="starttime"
                handleBlur={handleBlur}
                error={errors['starttime']}
              />
            </FormRow>
            <FormRow>
              <Field
                variant="date"
                width="mid"
                label="Einddatum"
                required
                value={values['enddate']}
                handleChange={(e, kbinput) => {
                  handleTimeChange(e, 'enddate')
                }}
                minDate={values['startdate'] || new Date()}
                id="enddate"
                name="enddate"
                handleBlur={() => handleDateBlur('enddate')}
                error={errors['enddate']}
              />
              <Field
                variant="time"
                width="small"
                label="Eindtijd"
                required={false}
                value={endtime}
                handleChange={(e, kbinput) => {
                  if (kbinput) {
                    const time =
                      kbinput.length == 1 || kbinput == 'Invalid date'
                        ? ''
                        : kbinput
                    setEndtime(time)
                    handleTimeChange(time, 'endtime')
                  } else {
                    setEndtime(e)
                    const time = getMomentTz(e).format('HH:mm')
                    handleTimeChange(time, 'endtime')
                  }
                }}
                id="endtime"
                name="endtime"
                handleBlur={handleBlur}
                error={errors['endtime']}
              />
            </FormRow>
          </div>
          <div className="subform">
            <TypographyElement variant="title">
              Gekoppelde locaties
            </TypographyElement>

            <FormRow>
              <Field
                variant="autocomplete"
                label="Locaties"
                description="Je kan hier 1 of meerdere sportlocaties selecteren"
                required={false}
                value={values['locations'] || []}
                handleChange={(e, value) => {
                  handleAutocompleteChange(e, value, 'locations')
                }}
                options={locationOptions}
                id="locations"
                name="locations"
                handleBlur={handleBlur}
                error={errors['locations']}
                boldlable={true}
                multiple={true}
              />
            </FormRow>
          </div>
          <div className="subform">
            <TypographyElement variant="title">
              Afwijkende plaats van evenement als er geen gekoppelde locatie
              mogelijk is
            </TypographyElement>

            <FormRow>
              <Field
                variant="text"
                label="Straat"
                required={false}
                value={values['street']}
                handleChange={handleChange}
                id="street"
                name="street"
                handleBlur={handleBlur}
                error={errors['street']}
              />
            </FormRow>
            <FormRow>
              <Field
                variant="number"
                width="small"
                label="Huisnummer"
                required={false}
                value={values['number']}
                handleChange={handleChange}
                id="number"
                name="number"
                handleBlur={handleBlur}
                error={errors['number']}
              />
              <Field
                variant="text"
                width="mid"
                label="Toevoeging"
                required={false}
                value={values['number_addon']}
                handleChange={handleChange}
                id="number_addon"
                name="number_addon"
                handleBlur={handleBlur}
                error={errors['number_addon']}
              />
            </FormRow>
            <FormRow>
              <Field
                variant="text"
                width="small"
                label="Postcode"
                required={false}
                value={values['postal_code']}
                handleChange={handleChange}
                id="postal_code"
                name="postal_code"
                handleBlur={handleBlur}
                error={errors['postal_code']}
              />
              <Field
                variant="text"
                width="mid"
                label="Plaats"
                required={false}
                value={values['place']}
                handleChange={handleChange}
                id="place"
                name="place"
                handleBlur={handleBlur}
                error={errors['place']}
              />
            </FormRow>
            <FormRow>
              <Field
                variant="select"
                width="small"
                label="Land"
                required={false}
                value={values['country']}
                handleChange={handleChange}
                options={countryOptions}
                id="country"
                name="country"
                handleBlur={handleBlur}
                error={errors['country']}
              />
              <Field
                variant="text"
                width="mid"
                label="Beschrijvende naam van de locatie"
                required={false}
                value={values['location_name']}
                handleChange={handleChange}
                id="location_name"
                name="location_name"
                handleBlur={handleBlur}
                error={errors['location_name']}
              />
            </FormRow>
          </div>
          <div className="subform">
            <TypographyElement variant="title">
              Algemene gegevens evenement
            </TypographyElement>

            <FormRow>
              <Field
                variant="text"
                label="Website"
                required={false}
                value={values['website']}
                handleChange={handleChange}
                id="website"
                name="website"

                handleBlur={async (e) => {
                  const { value } = e.target || {}
                  handleBlur(e)
                  // Check if title is available
                  if (value && values['website']) {
                    urlApiValidation(values['website']).then(
                      (res) => {
                        if (res.valid) {
                          setErrors((prevErrors) => {
                            if ("website" in prevErrors) {
                              delete prevErrors.website
                            }
                            return prevErrors
                          })
                        } else {
                          setErrors((prevErrors => ({
                            ...prevErrors,
                            website: res.message,
                          })))
                        }
                      }
                    )
                  }
                }}
                error={errors['website']}
              />
            </FormRow>
            <FormRow>
              <Field
                variant="email"
                label="E-mailadres"
                required={false}
                value={values['email']}
                handleChange={handleChange}
                id="email"
                name="email"
                handleBlur={handleBlur}
                error={errors['email']}
              />
            </FormRow>
            <FormRow>
              <Field
                variant="telephone"
                label="Telefoonnummer"
                required={false}
                value={values['telephone']}
                handleChange={handleChange}
                id="telephone"
                name="telephone"
                handleBlur={handleBlur}
                error={errors['telephone']}
              />
            </FormRow>
          </div>

          <div className="subform">
            <TypographyElement variant="title" component="h3">
              Afbeeldingen toevoegen aan het evenement
            </TypographyElement>
            <InputLabel
              required={true}
              htmlFor="header_files"
              error={errors['header_files']}
              boldlable={true}
            >
              Voeg hier je header afbeelding toe:
            </InputLabel>
            <TypographyElement variant="p">
              Let op: Minimaal formaat 1600x1200 pixels (max 3MB). Afbeelding
              mag geen tekst bevatten. Bij voorkeur een vierkant of liggend
              beeld.
            </TypographyElement>
            <Field
              variant="upload"
              label="Kies een foto"
              required={false}
              value={values['header_files']}
              handleChange={handleHeaderFileUpload}
              accept="image/*"
              id="header_files"
              name="header_files"
              error={errors['header_files'] || fileError}
              multiple
            />
            {'header_files' in values &&
              values['header_files'] &&
              values['header_files']?.map((file, ind) => {
                return (
                  <div key={`file-event-${ind}`} className="image-preview">
                    <ImageBuilderFrontend
                      alt="Evenement afbeelding"
                      image={file?.preview}
                      height={100}
                    />
                    <div
                      className="remove"
                      tabIndex={0}
                      onClick={() => handleRemoveHeaderFile(file)}
                    >
                      x
                    </div>
                  </div>
                )
            })}

            <InputLabel
              required={false}
              htmlFor="files"
              error={errors['files']}
              boldlable={true}
              sx={{
                marginTop: 4,
                paddingTop: 4,
                borderTop: '1px solid black',
              }}
            >
              Voeg hier extra afbeeldingen toe:
            </InputLabel>
            <TypographyElement variant="p">
              Let op: Minimaal formaat 1600x1200 pixels (max 3MB). Afbeelding
              mag geen tekst bevatten. Bij voorkeur een vierkant of liggend
              beeld.
            </TypographyElement>
            <Field
              variant="upload"
              label="Kies een foto"
              required={false}
              value={values['files']}
              handleChange={handleFileUpload}
              accept="image/*"
              id="files"
              name="files"
              error={errors['files'] || fileError}
              multiple
            />
            {'files' in values &&
              values['files'] &&
              values['files']?.map((file, ind) => {
                return (
                  <div key={`file-avent-${ind}`} className="image-preview">
                    <ImageBuilderFrontend
                      alt="Evenement afbeelding"
                      image={file?.preview}
                      height={100}
                    />
                    <div
                      className="remove"
                      tabIndex={0}
                      onClick={() => handleRemoveFile(file)}
                    >
                      x
                    </div>
                  </div>
                )
              })}
          </div>

          <div className="subform">
            <TypographyElement variant="title" component="h3">
              Uw gegevens
            </TypographyElement>
            <FormRow>
              <Field
                variant="text"
                label="Naam"
                required
                value={values['contact_name']}
                handleChange={handleChange}
                id="contact_name"
                name="contact_name"
                handleBlur={handleBlur}
                error={errors['contact_name']}
              />
            </FormRow>
            <FormRow>
              <Field
                variant="email"
                label="E-mailadres"
                required
                value={values['contact_email']}
                handleChange={handleChange}
                id="contact_email"
                name="contact_email"
                handleBlur={handleBlur}
                error={errors['contact_email']}
              />
            </FormRow>
            <FormRow>
              <Field
                variant="telephone"
                label="Telefoonnummer"
                required
                value={values['contact_telephone']}
                handleChange={handleChange}
                id="contact_telephone"
                name="contact_telephone"
                handleBlur={handleBlur}
                error={errors['contact_telephone']}
              />
            </FormRow>
          </div>
          <div className="buttons">
            <Field
              variant="checkbox"
              label="De uploader verklaart dat de organisatie toestemming geeft om het fotomateriaal te gebruiken ter promotie van het evenement door Sport050. De foto’s mogen gebruikt worden op de kanalen van Sport050."
              required={false}
              value={agreed}
              handleChange={() => setAgreed(!agreed)}
              id="checkbox"
              name="checkbox"
            />
            <ButtonElement
              variant="primary"
              disabled={disabled || processing || titleError}
              type="submit"
            >
              Evenement aanmelden
            </ButtonElement>
            {readableErrors && (
              <FormHelperText
                error={errors && true}
                id="component-error-text"
                sx={{
                  fontSize: 16,
                  textAlign: 'left !important',
                  width: '100% !important',
                }}
              >
                <b>Foutmelding: </b> Controleer de volgende velden:{' '}
                {readableErrors}
              </FormHelperText>
            )}
          </div>
        </LocalizationProvider>
      </Form>
    </FormWrapper>
  )
}

export default AddEventForm
