import React from 'react'
import styled from 'styled-components'

import TypographyElement from '../../elements/typography'
import WidthContainer from '../../elements/widthContainer'

import TextCta from '../../compositions/textCta'

import AddEventForm from '../../modules/addEventForm'
import { innerHtml } from '../../utils/innerHtml'
import { getApiData } from '../../utils/api/fetchApi'
import { buildLocalHost } from '@/core/utils/buildHost'

const Wrapper = styled.div`
  h2,
  p {
    max-width: 987px;
  }
  margin-bottom: 140px;
`

const AddEvent = ({ title, text, cta, handleFormSubmit }) => {
  const [eventtypes, setEventtypes] = React.useState([])
  const [sports, setSports] = React.useState([])
  const [clubs, setClubs] = React.useState([])
  const [attributes, setAttributes] = React.useState([])
  const [locations, setLocations] = React.useState([])
  const host = buildLocalHost()

  React.useEffect(() => {
    getApiData(host, 'eventtypes')
      .then((results) => {
        setEventtypes(results?.categories || [])
      })
      .catch((e) => {
        console.error(e)
        setEventtypes([])
      })
    getApiData(host, 'get_sports')
      .then((results) => {
        setSports(results?.categories || [])
      })
      .catch((e) => {
        console.error(e)
        setSports([])
      })
    getApiData(host, 'get_club_attributes')
      .then((results) => {
        setAttributes(results?.items || [])
      })
      .catch((e) => {
        console.error(e)
        setAttributes([])
      })
    getApiData(host, 'get_locations')
      .then((results) => {
        setLocations(results?.items || [])
      })
      .catch((e) => {
        console.error(e)
        setLocations([])
      })
    getApiData(host, 'get_clubs')
      .then((results) => {
        setClubs(results?.items || [])
      })
      .catch((e) => {
        console.error(e)
        setClubs([])
      })
  }, [host])

  return (
    <WidthContainer variant="full">
      <Wrapper>
        <div className="intro">
          <TypographyElement variant="title">{title}</TypographyElement>
          <TypographyElement variant="p">{innerHtml(text)}</TypographyElement>
          {cta && <TextCta {...cta} />}
        </div>
        <AddEventForm
          eventOptions={eventtypes}
          sportOptions={sports}
          attributeOptions={attributes}
          locationOptions={locations}
          clubOptions={clubs}
          host={host}
          handleFormSubmit={handleFormSubmit}
        />
      </Wrapper>
    </WidthContainer>
  )
}

export default AddEvent
